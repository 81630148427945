import React, {useEffect, useMemo} from 'react'
import { PageProps } from 'gatsby'
import { LayoutProps } from '../components/Layout.context'
import useSaveShopsQuery from '../hooks/useSaveShopsQuery'
import {Helmet} from "react-helmet";
import styled from "styled-components";
import useSiteMetadata from "../hooks/useSiteMetadata";

const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  text-align: center;
  font-size: 30px;
  line-height: 36px;
  margin: 100px 0;
  color: ${({ theme }) => theme.colors.secondary};
`

const ExternalSourceReparation: React.FC<PageProps> = () => {
  const isBrowser = () => typeof window !== 'undefined'
  const shops = useSaveShopsQuery()

  const siteMetadata = useSiteMetadata()

  let pathname = typeof window !== "undefined" ? window.location.pathname : ""

  const canonical = useMemo(
      () => `${siteMetadata?.siteUrlWithoutSlash}${pathname}`,
      [pathname, siteMetadata]
  )

  useEffect(() => {
    const params = isBrowser()
      ? new URLSearchParams(window.location.search)
      : new URLSearchParams()

    const shopId = params.get('shopId') ?? ''

    sessionStorage.clear()
    if (shopId !== '') {
      const shop = shops.find((shop) => {
        return shop.shopId?.toLowerCase() === shopId.toLocaleLowerCase()
      })
      if (shop != undefined) {
        sessionStorage.setItem('shopIdStored', shopId)
      }
    }

    // 👇️ redirects to an external URL
    window.location.replace('/reparation')
  }, [])

  return <>
    <Helmet>
      <meta name="robots" content="noindex"/>
      <link rel="alternate" href={canonical} hrefLang="fr-FR"/>
    </Helmet>
    <Title>Redirection vers le choix de votre modèle en cours...</Title>
  </>
}

const layoutProps: LayoutProps = {
  title: 'Save - Choix du magasin de réparation',
}

export default Object.assign(ExternalSourceReparation, {
  layoutProps,
})
